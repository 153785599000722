import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'



const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq pt-100">

      <Container>
        <div className="text-center">
          
          <h4 className="heading-h2"><span className="heading-h3 bluecolor">FAQ</span>
            Frequently Asked Questions</h4>
        </div>

        <Accordion defaultActiveKey={activeId}>
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">

                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" variant="link" eventKey="0">
                How long does it take to deploy a DeFi exchange with the Bakeryswap clone script?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="0">
                <div className="panel-body card-body">One of the best benefits of using our clone script is that you are not reinventing the wheel. Most of the framework is already there for you to use. In fact, you can use the script as it is. However, every software needs some level of optimization. Therefore, based on the extent of changes that you want, it can take anywhere between 7 - 10 days to deploy a fully functional DeFi Exchange using our BakerySwap clone software.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle" variant="link" eventKey="1">
                Is the Bakeryswap clone script Customizable?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">Our clone script is a white-label script. This means it is completely customizable and scalable as per the business needs. You can personalize the theme, design, key functions, and more without any hassle. You can hire our well-experienced development team to make customization in the source code. Once you have purchased our Bakeryswap clone script, it is up to you whether you want to use just the 10% of it or 100% of it
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle" variant="link" eventKey="2">
                Why choose the Bakeryswap clone script from us?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">Our well-skilled team of developers has extensive experience in delivering crypto-based projects with utmost perfection. We have delivered more than 300+ exchange projects using the respective clone script. We provide you with end-to-end blockchain & crypto solutions from initial prototyping to final deployment and maintenance, and everything in between.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '3' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle" variant="link" eventKey="3">
                What are the benefits of using the white-label BakerySwap clone software?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">You can yield various advantages by acquiring our clone script such as instant deployment, high customization scope, cost-effective, you can generate high ROI, comes with an impressive dashboard, pre-loaded with outstanding features, and more
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '4' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('4')} className="panel-toggle" variant="link" eventKey="4">
                What are the features of the BakerySwap clone script?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="4">
                <div className="panel-body card-body">Our premium BakerySwap clone software is inbuilt with some extraordinary features such as staking, liquidity pool, AMM protocol, multi-lingual support, transaction history, admin console, analytics info, and the list goes on.
                </div>
              </Accordion.Collapse>
            </div>
        </Accordion>
      </Container>

    </section>
  )
}

export default FaqSection