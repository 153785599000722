import React from 'react'



class WhyChoose extends React.Component {


  render() {

    return (
      <section className="whatis pt-100 mb-0 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              
              <h3 className="heading-h2"><span className="heading-h3 bluecolor">Why Choose</span>
                Coinsclone For BakerySwap Clone Script?</h3>
                <p className="pharagraph head">
              </p>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-5 col-md-12 left-side" >
              <img  width="513px" height="440px" src="https://coinsclone.mo.cloudinary.net/images/bakeryswap-new/why-choose-bakery-swap.png" alt="Why Choose our Bakeryswap Clone Script" />
            </div>
            <div className="col-lg-7 col-md-12">
              <p className="pharagraph">Coinsclone is the well-known and leading DeFi exchange clone script provider in the blockchain industry and we are a pioneer in offering world-class DeFi exchange clone development services. If you are planning to launch a successful food-themed DeFi exchange on the BSC network, then our Bakeryswap clone script is the perfect solution for initiating your dream DeFi business. Our premium BakerySwap clone script comes with 100% customization and it is highly responsive.
              </p>
              <p className="pharagraph mb-0">The BakerySwap clone script we provide is pre-loaded with the best possible security and the advanced AMM protocol. Staking APIs are inbuilt in the Binance Smart Chain, so they are available as a default functionality when you use our Bakeryswap clone script. Our Bakeryswap clone script has an inbuilt BEP20 token swapping mechanism and eye-catching UI/UX. The script we offer is secure, scalable, glitch-free, and protected with tamper-proof security modules to safeguard your DeFi exchange platform. In addition to these, we have a team of 50+ certified blockchain developers for crafting and delivering all kinds of crypto-based projects as per the needs of clients. 
              </p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WhyChoose