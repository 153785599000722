import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'



const DevApproach = () => {


  return (
    <section className="devapproach pt-100 mb-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h3 className="heading-h2">
              <span className="heading-h3">
                <span className="bluecolor">Development Approach</span> of
              </span>
              Our BakerySwap Clone Script{" "}
            </h3>
            <p className="pharagraph head">
              We at Coinsclone undergo the following procedures for Bakeryswap
              clone script development.
            </p>
          </div>
        </div>
        <div className="row table-content orderflex">
          <div className="col-lg-6 col-md-12  text-left order1">
            <StaticImage
              src="https://coinsclone.mo.cloudinary.net/images/bakeryswap-new/repuirement-gathering-planning.png"
              alt="Development approach"
              width={450}
            />
          </div>
          <div className="col-lg-6 col-md-12 padl60 order2">
            <h5>Requirement Gathering</h5>
            <p className="pharagraph">
              Our development team will sit with you and get a complete clarity
              about your requirement so that the Bakeryswap clone script can be
              leveraged to its maximum extent.
            </p>
            <br />
            <h5>Planning </h5>
            <p className="pharagraph">
              This will give you a visual sense of how the script integrates
              with your workflow and how one screen leads to the next.
            </p>
          </div>
        </div>
        <div className="row table-content orderflex">
          <div className="col-lg-6 col-md-12 padr40 order2">
            <h5>Designing</h5>
            <p className="pharagraph">
              Based on your input and our analysis, we will start building the
              interface as well as the back-end code. The Bakeryswap clone
              script code will be modified, and we may also need to add our own
              code.
            </p>
            <br />
            <h5>Development</h5>
            <p className="pharagraph">
              After developing, designing and testing, we will deploy the
              platform on the web. In case you have an app, we will also deploy
              the bakeryswap clone script powered mobile app on Apple and Google
              Play Stores.
            </p>
          </div>
          <div className="col-lg-6 col-md-12  text-right order1">
            <StaticImage
              src="https://coinsclone.mo.cloudinary.net/images/bakeryswap-new/design-and-development.png"
              alt="Designing"
              width={357}
            />
          </div>
        </div>
        <div className="row table-content orderflex mb-0">
          <div className="col-lg-6 col-md-12  text-left order2">
          <StaticImage
              src="https://coinsclone.mo.cloudinary.net/images/bakeryswap-new/testing.png"
              alt="Testing and Deployment"
              width={400}
            />
          </div>
          <div className="col-lg-6 col-md-12 padl60 order1">
            <h5>Testing</h5>
            <p className="pharagraph">
              This is just to make sure that everything works the way it should
              and there are no kinks.
            </p>
            <br />
            <h5>Deployment</h5>
            <p className="pharagraph mb-0">
              Once the platform is ready, it will be deployed on your web
              server. The appropriate app will also be deployed in its
              respective app store.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DevApproach